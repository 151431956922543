import React, { useCallback, useMemo } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"

import { IS_DEMO_MODE, PATHS } from "../../../constants"
import { useTimeFormat } from "../../../hooks/useTimeFormat"
import { getTimeNow } from "../../../utils"
import { useFormContext } from "./FormProvider"
import { Trans } from "@joan/joan-core"

import { meetLaterDemo } from "../../../redux/demoActions"
import { meetLater } from "../../../redux/events/actions"
import { selectIsCheckInAllowed } from "../../../redux/events/selectors"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { confirmActionSuccess } from "../../../redux/ui/actions"

import ConfirmActions from "../../../components/ConfirmActions"

import "./InitialForm.sass"

type Props = {
  onOpenDateStep: () => void
  onOpenTimeslotStep: () => void
}

const InitialForm = ({ onOpenDateStep, onOpenTimeslotStep }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { formValues, setFormValues } = useFormContext()

  const {
    date,
    timeslot: { slot, duration },
    title,
  } = formValues

  const isCheckInAllowed = useAppSelector((state) =>
    selectIsCheckInAllowed(state),
  )

  const isMeetLaterLoading = useAppSelector(
    (state) => state.meetLater.isLoading,
  )

  const { timeFormat } = useTimeFormat()

  const timeSlotFormatted = useMemo(() => {
    if (!slot || !duration) {
      return
    }

    const start = dayjs(slot)
    const end = start.add(duration, "minutes")

    return `${start.format(timeFormat)} - ${end.format(timeFormat)}`
  }, [duration, slot, timeFormat])

  const isDisabled = [title, date, slot, duration].some((item) => !item)

  const onSubmitForm = useCallback(async () => {
    if (!slot && !duration) {
      return
    }

    const now = getTimeNow()

    const start = dayjs(slot)
    const end = start.add(duration!, "minute")

    const isMeetNow = start.isBefore(now)
    const isAutoCheckinConfirm = isCheckInAllowed && isMeetNow

    if (start.isValid() && end.isValid()) {
      const response = await (IS_DEMO_MODE
        ? dispatch(meetLaterDemo(isMeetNow ? now : start, end, title))
        : dispatch(
            meetLater(
              isMeetNow ? now : start,
              end,
              title,
              isAutoCheckinConfirm,
            ),
          ))

      await dispatch(confirmActionSuccess())

      if (response?.ok) {
        history.push(PATHS.HOME)
      }
    }
  }, [slot, duration, isCheckInAllowed, dispatch, title, history])

  return (
    <div className="form initial-form">
      <div className="form-control">
        <label htmlFor="date">
          <Trans>Date</Trans>
        </label>
        <input
          id="date"
          name="date"
          value={formValues.date.format("LL")}
          autoComplete="off"
          onClick={onOpenDateStep}
          readOnly
        />
      </div>

      <div className="form-control">
        <label htmlFor="timeslot">
          <Trans>Timeslot</Trans>
        </label>
        <input
          id="timeslot"
          name="timeslot"
          value={timeSlotFormatted}
          autoComplete="off"
          onClick={onOpenTimeslotStep}
          readOnly
        />
      </div>

      <div className="form-control">
        <label htmlFor="title">
          <Trans>Title</Trans>
        </label>
        <input
          id="title"
          name="title"
          value={formValues.title}
          autoComplete="off"
          onChange={(e) =>
            setFormValues((prev) => ({
              ...prev,
              [e.target.name]: e.target.value,
            }))
          }
        />
      </div>

      <ConfirmActions
        isAutoSubmitConfirm
        autoBookedTime={duration}
        onSubmitConfirm={onSubmitForm}
        isDisabled={isDisabled || isMeetLaterLoading}
        isSubmitting={isMeetLaterLoading}
      />
    </div>
  )
}

export default InitialForm
