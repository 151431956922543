import React, { useCallback, useState } from "react"

import { availableDurations } from "../utils"
import { useFormContext } from "./FormProvider"
import { Trans } from "@joan/joan-core"

import Button from "../../../components/Button"
import GridSelect from "../../../components/GridSelect"

import CheckSVG from "../../../assets/icons/check.svg"

import "./TimeslotForm.sass"

type Props = {
  onOpenInitialForm: () => void
}

const TimeslotForm = ({ onOpenInitialForm }: Props) => {
  const {
    formValues,
    setFormValues,
    slots,
    durations,
    events,
    calcInitialDuration,
  } = useFormContext()

  const {
    timeslot: { slot, duration },
  } = formValues

  const [, setSlotCount] = useState(19)
  const [areSlotsExpanded, setAreSlotsExpanded] = useState(false)

  const handleChangeSlot = useCallback(
    (newSlot: number | null) => {
      const newDuration = calcInitialDuration(
        availableDurations(events, newSlot, 4),
        duration,
      )

      setFormValues((prev) => ({
        ...prev,
        timeslot: {
          ...prev.timeslot,
          slot: newSlot,
          duration: newDuration,
        },
      }))
    },
    [calcInitialDuration, duration, events, setFormValues],
  )

  const handleChangeDuration = (newDuration: number | null) => {
    setFormValues((prev) => ({
      ...prev,
      timeslot: { ...prev.timeslot, duration: newDuration },
    }))
  }

  const isDisabledConfirmButton = !slot || !duration

  return (
    <>
      <div className="form form-timeslot">
        <div>
          <div className="sub-title">
            <Trans>Start time</Trans>
          </div>
          <GridSelect
            items={slots}
            value={slot ?? null}
            onChange={handleChangeSlot}
            onExpand={() => {
              setSlotCount((prev) => prev + 32)
              setAreSlotsExpanded(true)
            }}
            isExpanded={areSlotsExpanded}
          />
        </div>

        <div>
          <div className="sub-title">
            <Trans>Duration</Trans>
          </div>
          <GridSelect
            items={durations}
            value={duration ?? null}
            onChange={handleChangeDuration}
            isExpanded
            isHorizontal
          />
        </div>
      </div>

      <div className="confirm-actions">
        <Button
          className="Confirm"
          onClick={onOpenInitialForm}
          isIconButton
          isDisabled={isDisabledConfirmButton}
        >
          <CheckSVG />
        </Button>
      </div>
    </>
  )
}

export default TimeslotForm
