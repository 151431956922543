import React, { useState } from "react"

import classNames from "classnames"
import { Dayjs } from "dayjs"

import { PATHS } from "../../constants"
import { Trans } from "@joan/joan-core"

import NavHeaderBar from "../../components/NavHeaderBar"
import CalendarForm from "./components/CalendarForm"
import { FormProvider } from "./components/FormProvider"
import InitialForm from "./components/InitialForm"
import TimeslotForm from "./components/TimeslotForm"

import "./style.sass"

export type FormValues = {
  date: Dayjs
  timeslot: {
    slot: number | null
    duration: number | null
  }
  title: string
}

type Step = "initial" | "selectDate" | "selectTimeslot"

const MeetLater = () => {
  const [currentStep, setCurrentStep] = useState<Step>("initial")

  const meetLaterClassName = classNames({
    MeetLater: true,
    Route: true,
  })

  const renderStep = () => {
    switch (currentStep) {
      case "selectDate":
        return (
          <CalendarForm onOpenInitialForm={() => setCurrentStep("initial")} />
        )
      case "selectTimeslot":
        return (
          <TimeslotForm onOpenInitialForm={() => setCurrentStep("initial")} />
        )
      case "initial":
      default:
        return (
          <InitialForm
            onOpenDateStep={() => setCurrentStep("selectDate")}
            onOpenTimeslotStep={() => setCurrentStep("selectTimeslot")}
          />
        )
    }
  }

  return (
    <div className={meetLaterClassName}>
      <NavHeaderBar
        title={<Trans>Meet later</Trans>}
        isShowCloseButton={currentStep === "initial"}
        isShowBackButton={currentStep !== "initial"}
        backPath={PATHS.MEET_LATER}
      />

      <FormProvider>{renderStep()}</FormProvider>
    </div>
  )
}

export default MeetLater
