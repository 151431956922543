import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

import dayjs, { Dayjs } from "dayjs"

import { TIME_INTERVAL_MINUTES } from "../../../constants"
import { eventConflicts, filterOptimistic, orderByDate } from "../../../utils"
import {
  DEFAULT_DURATION_MINUTES,
  MS_IN_A_MINUTE,
  SLOT_COUNT,
} from "../constants"
import { availabilitySlots, availableDurations } from "../utils"

import { CalendarEvent } from "../../../redux/events/types"
import { useAppSelector } from "../../../redux/store"

import { Item } from "../../../components/GridSelect"

type FormValues = {
  date: Dayjs
  timeslot: {
    slot: number | null
    duration: number | null
  }
  title: string
}

type CalcInitialSlot = (
  currentSlot: number,
  slotCounts: number,
  expiredSlots: number,
) => number | null

type CalcInitialDuration = (
  durations: Item[],
  currentDuration: number | null,
) => number | null

type FormContextProps = {
  formValues: FormValues
  setFormValues: (
    value: FormValues | ((prev: FormValues) => FormValues),
  ) => void
  slots: Item[]
  durations: Item[]
  events: CalendarEvent[]
  calcInitialSlot: CalcInitialSlot
  calcInitialDuration: CalcInitialDuration
}

const FormContext = createContext<FormContextProps | undefined>(undefined)

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const [formValues, setFormValues] = useState<FormValues>({
    date: dayjs(),
    timeslot: { slot: null, duration: DEFAULT_DURATION_MINUTES },
    title: "Occupied",
  })

  const {
    date,
    timeslot: { slot, duration },
  } = formValues

  const currentSlot = formValues.date.valueOf()

  const events = useAppSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )

  const expiredSlots = Math.floor(date.minute() / TIME_INTERVAL_MINUTES)

  const slots = useMemo(
    () => availabilitySlots(events, currentSlot, SLOT_COUNT, expiredSlots),
    [currentSlot, events, expiredSlots],
  )

  const durations = useMemo(
    () => availableDurations(events, slot, 4),
    [events, slot],
  )

  const calcInitialSlot = useCallback<CalcInitialSlot>(
    (currentSlot, slotCount, expiredSlots = 0) => {
      const slots = availabilitySlots(
        events,
        currentSlot,
        slotCount,
        expiredSlots,
      )

      const nextSlot = slots.find(({ value, isDisabled }) => {
        const doesConflict = eventConflicts(events, dayjs(value))
        return !doesConflict && !isDisabled
      })

      return nextSlot ? nextSlot.value : null
    },
    [events],
  )

  const calcInitialDuration = useCallback<CalcInitialDuration>(
    (durations, currentDuration) => {
      let currentValue = currentDuration

      if (!currentValue) {
        return null
      }

      while (currentValue >= TIME_INTERVAL_MINUTES) {
        const duration = durations.find(
          (item) => item.value === currentValue && !item.isDisabled,
        )

        if (duration) return currentValue

        currentValue -= TIME_INTERVAL_MINUTES
      }

      return null
    },
    [],
  )

  const now = dayjs()
  const isToday = date.isSame(now, "day")

  const syncTimeWithCurrent = useCallback(
    (durations: Item[]) => {
      const currentTime = dayjs()

      if (!isToday) {
        return
      }

      const updatedDate = date
        .hour(currentTime.hour())
        .minute(currentTime.minute())

      const initialSlot = calcInitialSlot(
        currentTime.valueOf(),
        SLOT_COUNT,
        expiredSlots,
      )

      const initialDuration = calcInitialDuration(durations, duration)

      setFormValues((prev) => {
        return {
          ...prev,
          date: updatedDate,
          timeslot: {
            slot: initialSlot,
            duration: initialDuration,
          },
        }
      })
    },
    [
      isToday,
      date,
      calcInitialSlot,
      expiredSlots,
      calcInitialDuration,
      duration,
    ],
  )

  useEffect(() => {
    const millisecondsUntilNextMinute =
      MS_IN_A_MINUTE - now.millisecond() - now.second() * 1000

    if (isToday) {
      syncTimeWithCurrent(durations)

      const timeoutId = setTimeout(() => {
        const intervalId = setInterval(() => {
          syncTimeWithCurrent(durations)
        }, MS_IN_A_MINUTE)

        return () => clearInterval(intervalId)
      }, millisecondsUntilNextMinute)

      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToday, events.length])

  const contextValue = useMemo(
    () => ({
      formValues,
      setFormValues,
      slots,
      durations,
      events,
      calcInitialSlot,
      calcInitialDuration,
    }),
    [
      formValues,
      slots,
      durations,
      events,
      calcInitialSlot,
      calcInitialDuration,
    ],
  )

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  )
}

export const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context)
    throw new Error("useFormContext must be used within a FormProvider")
  return context
}
