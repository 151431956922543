/**
 * The number of 15-minute intervals in a day.
 *
 * A day consists of 24 hours, and each hour contains 4 intervals of 15 minutes.
 * Total intervals in a day = 24 * 4 = 96.
 * However, the 96th interval represents the start of the next day (00:00),
 * so we use 95 intervals for the current day.
 */
export const SLOT_COUNT = 24 * (60 / 4) - 1

export const DEFAULT_DURATION_MINUTES = 30

export const MS_IN_A_MINUTE = 60000
