import React, { useCallback, useState } from "react"

import classNames from "classnames"
import dayjs, { Dayjs } from "dayjs"

import { SLOT_COUNT } from "../constants"
import { availableDurations } from "../utils"
import { useFormContext } from "./FormProvider"
import { Trans } from "@joan/joan-core"

import Button from "../../../components/Button"

import ArrowLeftSVG from "../../../assets/icons/arrow_left.svg"
import ArrowRightSVG from "../../../assets/icons/arrow_right.svg"
import CheckSVG from "../../../assets/icons/check.svg"

import "./CalendarForm.sass"

type Props = {
  onOpenInitialForm: () => void
}

const CalendarForm = ({ onOpenInitialForm }: Props) => {
  const {
    formValues: {
      date,
      timeslot: { slot, duration },
    },
    setFormValues,
    events,
    calcInitialSlot,
    calcInitialDuration,
  } = useFormContext()

  const [currentMonth, setCurrentMonth] = useState(dayjs())

  const handlePrevMonth = () =>
    setCurrentMonth(currentMonth.subtract(1, "month"))
  const handleNextMonth = () => setCurrentMonth(currentMonth.add(1, "month"))

  const monthDays = generateMonthDays(currentMonth)
  const weekDays = generateWeekDays()

  const calculateNewSlot = (day: Dayjs, currentSlot: number): number => {
    const isToday = dayjs().isSame(day, "day")

    if (!isToday) {
      const currentMinutes = dayjs(currentSlot).minute()
      const roundedMinutes = roundMinutesToNearest15(currentMinutes)

      return dayjs(day)
        .hour(dayjs(currentSlot).hour())
        .minute(roundedMinutes % 60)
        .second(0)
        .add(Math.floor(roundedMinutes / 60), "hour")
        .valueOf()
    }

    return currentSlot
  }

  const handleUpdateDateTimeSlot = useCallback(
    (day: Dayjs) => {
      const newSlot = calculateNewSlot(day, slot!)

      const durations = availableDurations(events, newSlot, 4)

      const initialSlot = calcInitialSlot(newSlot, SLOT_COUNT, 0)
      const initialDuration = calcInitialDuration(durations, duration)

      setFormValues((prev) => ({
        ...prev,
        date: day,
        timeslot: {
          slot: initialSlot,
          duration: initialDuration,
        },
      }))
    },
    [
      slot,
      duration,
      events,
      calcInitialSlot,
      calcInitialDuration,
      setFormValues,
    ],
  )

  const isDisabledConfirmButton = !date

  return (
    <>
      <div className="form form-calendar">
        <div className="label">
          <Trans>Select date</Trans>
        </div>

        <div className="calendar">
          <div className="calendar__month">
            {currentMonth.format("MMMM YYYY")}
          </div>
          <div className="calendar__grid">
            {weekDays.map((day, index) => (
              <div className="calendar__weekday" key={index}>
                {day}
              </div>
            ))}

            {monthDays.map((day, index) => (
              <div
                key={index}
                onClick={() => handleUpdateDateTimeSlot(day)}
                data-date={day.format("DD-MM-YYYY HH:mm")}
                className={classNames("calendar__day", {
                  "calendar__day--selected": date && date.isSame(day, "day"),
                  "calendar__day--current": day.isSame(dayjs(), "day"),
                  "calendar__day--prev": day.isBefore(dayjs(), "day"),
                  "calendar__day--disabled":
                    day.isBefore(dayjs(), "day") ||
                    day.isAfter(dayjs().add(6, "day"), "day"),
                })}
              >
                {day.date()}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="confirm-actions">
        <div className="confirm-actions-group">
          <Button className="PrevMonth" onClick={handlePrevMonth} isIconButton>
            <ArrowLeftSVG />
          </Button>

          <Button className="NextMonth" onClick={handleNextMonth} isIconButton>
            <ArrowRightSVG />
          </Button>
        </div>

        <Button
          className="Confirm"
          onClick={onOpenInitialForm}
          isIconButton
          isDisabled={isDisabledConfirmButton}
        >
          <CheckSVG />
        </Button>
      </div>
    </>
  )
}

export default CalendarForm

const generateMonthDays = (currentMonth: Dayjs) => {
  const startOfMonth = currentMonth.startOf("month")
  const endOfMonth = currentMonth.endOf("month")
  const days = []

  const prevDaysCount = startOfMonth.day() !== 0 ? startOfMonth.day() : 6
  for (let i = prevDaysCount; i > 1; i--) {
    days.push(startOfMonth.subtract(i, "day"))
  }

  for (let i = 0; i < endOfMonth.date(); i++) {
    days.push(startOfMonth.add(i, "day"))
  }

  const nextDaysCount = (7 - endOfMonth.day()) % 7
  for (let i = 1; i <= nextDaysCount; i++) {
    days.push(endOfMonth.add(i, "day"))
  }

  return days
}

const generateWeekDays = () => {
  const days = []
  for (let i = 1; i <= 7; i++) {
    days.push(dayjs().day(i).format("dd")) // We get the abbreviation of the day of the week depending on the locale
  }
  return days
}

const roundMinutesToNearest15 = (minutes: number): number =>
  Math.ceil(minutes / 15) * 15
