import dayjs from "dayjs"

import { TIME_INTERVAL_MINUTES } from "../../constants"
import {
  eventConflicts,
  filterOptimistic,
  getTimeNow,
  zeroMinutes,
} from "../../utils"
import {
  getNextEventFromNow,
  getOrdinalFromNumber,
  getTime,
} from "@joan/joan-core"

import { CalendarEvent } from "../../redux/events/types"

const generateDurations = (count: number) => {
  const durations = []

  for (var i = 1; i <= count; i++) {
    const value = i * 15
    durations.push({
      value,
      isDisabled: false,
      isFloored: false,
    })
  }

  return durations
}

export function availableDurations(
  events: CalendarEvent[],
  slotValue: number | null,
  count = 3,
) {
  const now = getTimeNow()

  const date = dayjs(slotValue)

  const safeValue = slotValue ? date : now

  const nextEvent = getNextEventFromNow(filterOptimistic(events), safeValue)

  const isNextInLessThan15 =
    nextEvent &&
    date.add(TIME_INTERVAL_MINUTES, "minute").isAfter(getTime(nextEvent.start))

  const durations = generateDurations(!isNextInLessThan15 ? count : count - 1)

  if (isNextInLessThan15) {
    const diff = getTime(nextEvent.start).unix() - date.unix()

    const minutes = Math.floor(diff / 60)

    if (minutes > 0) {
      durations.unshift({
        value: Math.floor(diff / 60),
        isDisabled: false,
        isFloored: true,
      })
    }
  }

  return durations.map(({ value, isFloored }) => {
    const isSlotInPast = slotValue && dayjs(slotValue).isBefore(date)
    const eventEnd = dayjs(isSlotInPast ? now : safeValue).add(value, "minute")
    const isDisabled = nextEvent && getTime(nextEvent.start).isBefore(eventEnd)

    const hours = Math.floor(value / 60)
    const minutes = value % 60

    // TODO: do not hard code time formatting. Use locale functions.
    return {
      label: hours
        ? `${hours}${
            minutes ? `h ${minutes}min` : hours === 1 ? " hour" : " hours"
          }`
        : `${minutes}min${isFloored ? "+" : ""}`,
      value,
      isDisabled,
    }
  })
}

export function availabilitySlots(
  events: CalendarEvent[],
  value: number,
  count = 15,
  offset = 0,
) {
  const datetime = dayjs(value)
  const nextIncrement = zeroMinutes(datetime).add(offset * 15, "minute")
  const endOfDay = datetime
    .endOf("day")
    .subtract(TIME_INTERVAL_MINUTES, "minute")

  const slots = [
    {
      value: value,
      time: datetime,
      isDisabled: eventConflicts(events, datetime),
    },
  ]

  // Backfill unavailable slots, so that we start at round times XY:00
  for (let i = offset; i > 0; i--) {
    const additionalMinutes = TIME_INTERVAL_MINUTES * (i - 1)
    const newDateTime = zeroMinutes(datetime).add(additionalMinutes, "minute")

    slots.unshift({
      value: newDateTime.valueOf(),
      time: newDateTime,
      isDisabled: true,
    })
  }

  // Add new slots with availability set
  for (let k = 1; slots.length < count; k++) {
    const additionalMinutes = TIME_INTERVAL_MINUTES * k
    const newDateTime = nextIncrement.add(additionalMinutes, "minute")

    if (newDateTime.isAfter(endOfDay)) {
      break
    }

    slots.push({
      value: newDateTime.valueOf(),
      time: newDateTime,
      isDisabled: eventConflicts(events, newDateTime),
    })
  }

  return slots
}

export function formatDate(date: string) {
  // TODO: do not hard code time formatting. Use locale functions.
  return `${getTime(date).format("MMM")} ${getOrdinalFromNumber(
    getTime(date).date(),
  )}`
}
