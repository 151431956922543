export const NAVIGATE_HOME_TIMEOUT = import.meta.env.VITE_NAVIGATE_HOME_TIMEOUT
  ? Number(import.meta.env.VITE_NAVIGATE_HOME_TIMEOUT) * 1000
  : 30_000

export const PATHS = Object.freeze({
  ROOT: "/",
  HOME: "/home",
  MEET_NOW: "/meet-now",
  MEET_LATER: "/meet-later",
  TIMETABLE: "/timetable",
  MEET_DETAILS: (id: string) => `/meet/${id}`,
  QR_CODE: "/qr-code",
  WEB_HOOK: "/web-hook",
  VERSION: "/version",
})

export const IS_DEMO_MODE = import.meta.env.VITE_APP_IS_DEMO || false

export const DEFAULT_EXTEND_MINUTES = Number(
  import.meta.env.VITE_APP_EXTEND_MEETING_MINUTES || 15,
)

export const TIME_INTERVAL_MINUTES = 15

export const DEVICE_TYPES = {
  CRESTRON: "Crestron",
  PRO_DVX: "ProDVX",
  JOT: "JoT",
} as const
